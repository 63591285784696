import { FC } from 'react';
import { Button, message, Upload } from 'antd';
import { UploadRequestOption } from 'rc-upload/es/interface';
import { UploadOutlined } from '@ant-design/icons';
import { TypePassport } from 'types/index';
import { UploadFile } from 'antd/es/upload/interface';
import { useDeleteDevicePassportMutation, useUploadDevicePassportMutation } from 'store/devices/devices-api';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../../lib/format-date';

type Props = {
  initialValues: TypePassport[];
};

export const DevicePassports: FC<Props> = (props) => {
  const { initialValues } = props;

  const { id } = useParams();
  const [uploadPassport] = useUploadDevicePassportMutation();
  const [deletePassport] = useDeleteDevicePassportMutation();

  const handlerUploadPassport = async (options: UploadRequestOption) => {
    const { file } = options;

    if (!file || !id) {
      return;
    }

    const res = await uploadPassport({ deviceId: +id, file });

    if ('error' in res) {
      message.error('Ошибка при загрузке паспорта');
    } else {
      message.success(res.data);
    }
  };

  const handlerRemovePassport = async (options: UploadFile) => {
    const res = await deletePassport({ id: +options.uid });

    if ('error' in res) {
      message.error('Ошибка при удалении паспорта');
      return false;
    } else {
      message.success(res.data);
      return true;
    }
  };

  return (
    <Upload.Dragger
      listType="picture"
      customRequest={handlerUploadPassport}
      multiple
      accept=".pas"
      onRemove={handlerRemovePassport}
      fileList={initialValues.map((file) => ({
        uid: file.id.toString(),
        name: `${file.name} (${formatDate(file.createdAt)})`,
        status: 'done',
      }))}
    >
      <Button icon={<UploadOutlined />}>Нажимите на кнопку или перетащите паспорта для загрузки</Button>
    </Upload.Dragger>
  );
};
