import { useEffect, useState } from 'react';
import s from './style.module.scss';
import logo from '../../assets/images/main-logo.png';
import { MenuFoldOutlined, MenuUnfoldOutlined, MobileOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { logout } from 'store/auth/auth.slice';
import { useDispatch } from '../../hooks/use-dispatch';

const { Header, Sider, Content } = Layout;

const items = [
  {
    key: 'users',
    label: 'Пользователи',
    path: `/users`,
    icon: <UserOutlined />,
  },
  {
    key: 'devices',
    label: 'Устройства',
    path: `/devices`,
    icon: <ProfileOutlined />,
  },
  {
    key: 'my-devices',
    label: 'Мои устройства',
    path: `/my-devices`,
    icon: <MobileOutlined />,
  },
];
export const MainLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentLocation = items.find((_item) => location.pathname.split('/')[1] === _item.key)?.key;
  const [selectedKey, setSelectedKey] = useState<string[] | undefined>();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    if (!currentLocation) {
      setSelectedKey(undefined);
    } else {
      setSelectedKey([currentLocation]);
    }
  }, [currentLocation]);

  const handleClickMenu = (item: MenuInfo) => {
    const clicked = items.find((_item) => _item.key === item.key)?.path;

    if (!clicked) {
      return;
    }

    navigate(clicked);
  };

  const handleClickLogout = () => {
    dispatch(logout());
  };

  const handleClickLogo = () => {
    navigate('/');
  };

  return (
    <Layout className={s.layout}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className={s.logoWrapper} onClick={handleClickLogo}>
          <img className={s.logo} src={logo} alt="тэк" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={selectedKey}
          items={items}
          onClick={handleClickMenu}
          selectedKeys={selectedKey}
        />
      </Sider>
      <Layout>
        <Header className={s.header} style={{ background: colorBgContainer }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            className={s.triggerButton}
          />
          <Button type="primary" danger onClick={handleClickLogout}>
            Выйти
          </Button>
        </Header>
        <Content
          className={s.content}
          style={{
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
