import { FC } from 'react';
import s from './style.module.scss';
import { Button, Form, FormProps, Input } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';

type Props = FormProps & {
  onFinish: (values: { email: string; password: string }) => void;
  isLoading: boolean;
  formValid?: boolean;
};

export const LoginForm: FC<Props> = ({ isLoading, formValid, initialValues, ...props }) => {
  return (
    <Form initialValues={{ remember: true, ...initialValues }} {...props}>
      <Form.Item
        name="email"
        rules={[
          { type: 'email', message: 'Некорректный E-mail!' },
          { required: true, message: 'Пожалуйста введите своей Email!' },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Введите email" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста введите свой пароль!' }]}>
        <Input.Password prefix={<LockOutlined />} type="password" placeholder="Введите пароль" />
      </Form.Item>

      <Button type="primary" htmlType="submit" className={s.submitButton} loading={isLoading} disabled={!formValid}>
        Войти
      </Button>
    </Form>
  );
};
