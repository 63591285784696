import { ColumnsType } from 'antd/es/table';
import { TypeUser } from 'types/index';
import { EnumUserAuthType, EnumUserRole } from 'variables/user';
import { getUserRoleRow } from './rows/user-role-row';
import { getUserAuthTypeRow } from './rows/user-auth-type-row';
import { ActionRow } from 'components/shared/table-controls/action-row';
import { NoDataRow } from 'components/shared/table-controls/no-data-row';

type Params = {
  handleDeleteUser: (id: string) => void;
};

export const userTableColumns = (params: Params): ColumnsType<TypeUser> => {
  const { handleDeleteUser } = params;

  return [
    {
      title: 'Имя',
      dataIndex: 'firstName',
      render: (firstName?: string) => (firstName ? firstName : <NoDataRow />),
    },
    {
      title: 'Фамилия',
      dataIndex: 'lastName',
      render: (lastName?: string) => (lastName ? lastName : <NoDataRow />),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email?: string) => (email ? email : <NoDataRow />),
    },
    {
      title: 'Организация',
      dataIndex: 'organization',
      render: (organization?: string) => (organization ? organization : <NoDataRow />),
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      filters: [
        {
          text: 'Admin',
          value: EnumUserRole.ADMIN,
        },
        {
          text: 'User App',
          value: EnumUserRole.USER_APP,
        },
        {
          text: 'User Web',
          value: EnumUserRole.USER_WEB,
        },
      ],
      onFilter: (value, user) => user.role === value,
      render: (role: EnumUserRole) => getUserRoleRow(role),
    },
    {
      title: 'Тип авторизации',
      dataIndex: 'authType',
      render: (type: EnumUserAuthType) => getUserAuthTypeRow(type),
    },
    {
      title: 'Действие',
      key: 'action',
      fixed: 'right',
      width: 360,
      render: (_, user) => (
        <ActionRow
          disableDelete={user.role === EnumUserRole.ADMIN}
          onDelete={() => handleDeleteUser(user.id.toString())}
          entityId={user.id.toString()}
        />
      ),
    },
  ];
};
