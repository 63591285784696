import { api } from '../api';
import { TypeUser } from 'types/index';
import { setUsersList } from 'store/users/users.slice';

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsersList: builder.query<TypeUser[], string>({
      query: () => ({
        url: '/web/user/all',
      }),
      providesTags: ['User'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: usersList } = await queryFulfilled;
          dispatch(setUsersList({ usersList }));
        } catch (e) {
          console.log(e);
        }
      },
    }),
    deleteUser: builder.mutation<string, { id: string }>({
      query: ({ id }) => ({
        url: `/web/user/delete/${id}`,
        method: 'DELETE',
        responseHandler: 'text',
      }),
      invalidatesTags: ['User'],
    }),
    createUser: builder.mutation<TypeUser, TypeUser>({
      query: (user) => ({
        url: '/web/user/create',
        method: 'POST',
        body: { ...user },
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<TypeUser, TypeUser>({
      query: (user) => ({
        url: '/web/user/update',
        method: 'POST',
        body: { ...user },
      }),
      invalidatesTags: ['User', 'Me'],
    }),
    userResetPassword: builder.mutation<null, { email: string }>({
      query: ({ email }) => ({
        url: '/email/web/reset-password-user',
        method: 'POST',
        params: { email },
        responseHandler: 'text',
      }),
    }),
  }),
});

export const {
  useGetUsersListQuery,
  useDeleteUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUserResetPasswordMutation,
} = usersApi;
