import { EnumUserAuthType } from 'variables/user';
import { Tag } from 'antd';

export const getUserAuthTypeRow = (type: EnumUserAuthType) => {
  switch (type) {
    case EnumUserAuthType.MOBILE:
      return <Tag color="cyan">Mobile</Tag>;
    case EnumUserAuthType.VK:
      return <Tag color="blue">VK</Tag>;
    case EnumUserAuthType.GOOGLE:
      return <Tag color="geekblue">Google</Tag>;
    case EnumUserAuthType.PASSWORD:
      return <Tag color="green">Password</Tag>;
    default:
      return <Tag>Не известный тип авторизации</Tag>;
  }
};
