import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { ErrorComponent } from 'components/error-component';
import { DeviceTabs } from 'components/device/device-tabs';
import { useSelector } from '../../hooks/use-selector';
import { useGetDevicesListQuery, useUpdateDeviceMutation } from 'store/devices/devices-api';
import { Loader } from 'components/loader';
import { TypeCreateDevice, TypeUploadDevice } from 'types/index';
import { message } from 'antd';
import { RcFile } from 'antd/es/upload';

const defaultFile = new Blob([''], { type: '' }) as RcFile;

export const DeviceUpdatePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, isFetching } = useGetDevicesListQuery('devicesList');
  const [updateDevice, { isLoading: isLoadingUpdateDevice }] = useUpdateDeviceMutation();
  const devices = useSelector((state) => state.devices.devicesList);

  if (isLoading || isFetching) {
    return <Loader />;
  }

  const currentDevice = devices.find((device) => device.id.toString() === id);

  if (!currentDevice) {
    return (
      <ErrorComponent
        subTitle="Устройство не найдено"
        title="404"
        extraButtonText="Назад"
        handleClickButton={() => navigate(-1)}
      />
    );
  }

  const onFinishInfo = async (device: TypeCreateDevice) => {
    const deviceData: TypeUploadDevice = {
      id: currentDevice.id,
      name: device.name,
      description: device.description,
    };

    if (device.file) {
      deviceData.file = device.file.fileList.length ? device.file.fileList[0].originFileObj : defaultFile;
    }

    const res = await updateDevice(deviceData);

    if ('error' in res) {
      message.error('Ошибка при обновлении устройства');
    } else {
      message.success(`Устройство ${res.data.name} обновлено`);
      navigate(-1);
    }
  };

  return (
    <DeviceTabs
      initialValues={currentDevice}
      onFinishInfo={onFinishInfo}
      devicePath={currentDevice.id.toString()}
      isLoading={isLoadingUpdateDevice}
    />
  );
};
