import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeUser } from 'types/index';

type initialState = {
  usersList: TypeUser[];
};

const initialState: initialState = {
  usersList: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersList: (state, action: PayloadAction<{ usersList: TypeUser[] }>) => {
      state.usersList = action.payload.usersList;
    },
  },
});

export const { setUsersList } = usersSlice.actions;
export const { reducer: usersReducer } = usersSlice;
