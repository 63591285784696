import { api } from '../api';
import { login, logout, setCredentials } from 'store/auth/auth.slice';
import { EnumUserAuthType } from 'variables/user';
import { TypeLoginResponse, TypeUser } from 'types';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<TypeLoginResponse, { email: string; password: string }>({
      query: (credentials) => ({
        url: '/auth/authenticate',
        method: 'POST',
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation<null, { email: string }>({
      query: ({ email }) => ({
        url: '/email/web/reset-password',
        method: 'POST',
        params: { email },
        responseHandler: 'text',
      }),
    }),
    me: builder.query<TypeUser, string>({
      query: () => ({
        url: '/profile/me',
      }),
      providesTags: ['Me'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          dispatch(login(res.data));
        } catch (e) {
          dispatch(logout());
        }
      },
    }),
    loginSocialNetworks: builder.mutation<
      TypeLoginResponse,
      { token: string; tokenType: EnumUserAuthType; email?: string }
    >({
      query: ({ token, tokenType, email }) => ({
        url: '/auth/web/login',
        method: 'POST',
        body: { token, tokenType, email },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          dispatch(setCredentials(res.data));
          dispatch(login(res.data.userResponse));
        } catch (e) {
          dispatch(logout());
          console.log(e);
        }
      },
    }),
  }),
});

export const { useLoginMutation, useMeQuery, useResetPasswordMutation, useLoginSocialNetworksMutation } = authApi;
