import s from './style.module.scss';
import { UserForm } from 'components/user/user-form';
import { TypeUser } from 'types/index';
import { useParams } from 'react-router-dom';
import { ErrorComponent } from 'components/error-component';
import { useNavigate } from 'react-router';
import { useSelector } from '../../hooks/use-selector';
import { useGetUsersListQuery, useUpdateUserMutation, useUserResetPasswordMutation } from 'store/users/users-api';
import { Loader } from 'components/loader';
import { Button, message } from 'antd';
import { EnumUserAuthType, EnumUserRole } from 'variables/user';

export const UserUpdatePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, isFetching } = useGetUsersListQuery('usersList');
  const [updateUser, { isLoading: isLoadingUpdateUser }] = useUpdateUserMutation();
  const [userResetPassword, { isLoading: isLoadingResetPassword }] = useUserResetPasswordMutation();
  const user = useSelector((state) => state.auth.user);
  const currentUser = useSelector((state) => state.users.usersList.find((user) => user.id.toString() === id));

  if (isLoading || isFetching) {
    return <Loader />;
  }

  if (!currentUser) {
    return (
      <ErrorComponent
        subTitle="Пользователь не найден"
        title="404"
        extraButtonText="Назад"
        handleClickButton={() => navigate(-1)}
      />
    );
  }

  const onFinish = async (user: TypeUser) => {
    const res = await updateUser({ ...currentUser, ...user });
    if ('error' in res) {
      message.error('Ошибка при обновлении пользователя');
    } else {
      const userEmail = res.data.email ? ` ${res.data.email}` : '';
      message.success(`Пользователь${userEmail} обновлен`);
      navigate(-1);
    }
  };

  const handleResetPassword = async () => {
    if (!currentUser.email) {
      return;
    }

    const res = await userResetPassword({ email: currentUser.email });

    if ('error' in res) {
      message.error('Ошибка при сбросе пароля');
    } else {
      message.success('Новый пароль отправлен на почту');
    }
  };

  const disabledResetPasswordBtn =
    currentUser.id === user?.id ||
    user?.role !== EnumUserRole.ADMIN ||
    !currentUser.email ||
    currentUser.authType === EnumUserAuthType.VK ||
    currentUser.authType === EnumUserAuthType.GOOGLE;

  return (
    <>
      <Button
        type="primary"
        danger
        onClick={handleResetPassword}
        className={s.resetButton}
        loading={isLoadingResetPassword}
        disabled={disabledResetPasswordBtn}
      >
        Сбросить пароль пользователя
      </Button>
      <UserForm isEditForm={true} initialValues={currentUser} onFinish={onFinish} isLoading={isLoadingUpdateUser} />
    </>
  );
};
