import { UserForm } from 'components/user/user-form';
import { TypeUser } from 'types/index';
import { EnumUserAuthType, EnumUserRole } from 'variables/user';
import { useCreateUserMutation } from 'store/users/users-api';
import { useNavigate } from 'react-router';
import { message } from 'antd';

const defaultUser: TypeUser = {
  id: Date.now(),
  role: EnumUserRole.USER_APP,
  lastName: '',
  firstName: '',
  email: '',
  authType: EnumUserAuthType.PASSWORD,
  organization: '',
};

export const UserCreatePage = () => {
  const navigate = useNavigate();
  const [createUser, { isLoading }] = useCreateUserMutation();

  const onFinish = async (user: TypeUser) => {
    const res = await createUser(user);
    if ('error' in res) {
      message.error('Ошибка при создании пользователя');
    } else {
      const userEmail = res.data.email ? ` ${res.data.email}` : '';
      message.success(`Пользователь${userEmail} успешно создан! Пароль отправлен на указанный email.`, 2);
      navigate(-1);
    }
  };

  return <UserForm isEditForm={false} initialValues={defaultUser} onFinish={onFinish} isLoading={isLoading} />;
};
