import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tokenService } from '../../services/token.service';
import { TypeLoginResponse, TypeUser } from 'types/index';

type initialState = {
  isAuth: boolean;
  user: TypeUser | null;
};

const initialState: initialState = {
  isAuth: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<TypeLoginResponse>) => {
      const { accessToken, userResponse } = action.payload;
      tokenService.saveToken(accessToken);
      state.isAuth = true;
      state.user = userResponse;
    },
    login: (state, action: PayloadAction<TypeUser>) => {
      state.isAuth = true;
      state.user = action.payload;
    },
    logout: (state) => {
      tokenService.removeToken();
      state.isAuth = false;
    },
  },
});

export const { setCredentials, logout, login } = authSlice.actions;
export const { reducer: authReducer } = authSlice;
