import { HeartOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { TypeDevice } from 'types/index';
import { addFavoriteDevice } from 'store/devices/devices.slice';
import { useDispatch } from '../../../../hooks/use-dispatch';
import { FC } from 'react';
import { useSelector } from '../../../../hooks/use-selector';

type Props = {
  device: TypeDevice;
};

export const AddFavoriteButton: FC<Props> = (props) => {
  const { device } = props;
  const dispatch = useDispatch();
  const favoriteDevices = useSelector((state) => state.devices.favoriteDevicesList);

  const handleAddFavoriteDevice = (device: TypeDevice) => {
    dispatch(addFavoriteDevice(device));
    message.success(`Устройство ${device.name} успешно добавлено в список "Мои устройства"`);
  };

  return (
    <Button
      type="primary"
      icon={<HeartOutlined />}
      onClick={() => handleAddFavoriteDevice(device)}
      disabled={favoriteDevices.findIndex((d) => d.id === device.id) >= 0}
    >
      Добавить в мои устройства
    </Button>
  );
};
