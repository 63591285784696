import s from './style.module.scss';
import { FC, ReactNode } from 'react';
import { Button, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

type Props = {
  entityId: string;
  onDelete?: () => void;
  disableDelete: boolean;
  children?: ReactNode;
};

export const ActionRow: FC<Props> = (props) => {
  const { entityId, onDelete, disableDelete, children } = props;

  return (
    <div className={s.controlsWrapper}>
      <Link to={entityId}>
        <Button type="primary" icon={<EditOutlined />}>
          Редактировать
        </Button>
      </Link>
      <Divider type="vertical" />
      {onDelete && (
        <Button danger onClick={onDelete} disabled={disableDelete} icon={<DeleteOutlined />}>
          Удалить
        </Button>
      )}
      {children}
    </div>
  );
};
