import { useEffect, useState } from 'react';
import { LoginForm } from 'components/login-form';
import s from './style.module.scss';
import { useNavigate } from 'react-router';
import { useLoginMutation, useLoginSocialNetworksMutation, useResetPasswordMutation } from 'store/auth/auth-api';
import { setCredentials } from 'store/auth/auth.slice';
import { Button, message } from 'antd';
import { useDispatch } from '../../hooks/use-dispatch';
import { RecoveryPwdForm } from 'components/login-form/recovery-pwd-form';
import logo from 'assets/images/main-logo.png';
import { useLocation } from 'react-router-dom';
import { EnumUserAuthType } from 'variables/user';
import { IErrorResponse } from 'types/index';
import { VkLoginButton } from 'components/login-form/vk-login-button';
import { vkResponseParse } from '../../lib/vk-response-parse';

const googleId = process.env.REACT_APP_GOOGLE_ID as string;

export const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const [loginSocialNetwork, { isLoading: isLoadingLoginSocials }] = useLoginSocialNetworksMutation();
  const [resetPassword] = useResetPasswordMutation();
  const [formValid, setFormValid] = useState(true);
  const [isResetPwd, setResetPwd] = useState(false);

  useEffect(() => {
    if (isResetPwd) {
      return;
    }

    google.accounts.id.initialize({
      client_id: googleId,
      callback: (res) => {
        loginSocialNetwork({ token: res.credential, tokenType: EnumUserAuthType.GOOGLE });
      },
    });

    const socialsDIV = document.getElementById('google');

    if (!socialsDIV) {
      return;
    }

    google.accounts.id.renderButton(socialsDIV, { type: 'icon' });
  }, [isResetPwd]);

  useEffect(() => {
    const { vkToken, vkUserEmail } = vkResponseParse(location.hash);

    if (!vkToken || !vkUserEmail) {
      return;
    }

    loginSocialNetwork({ token: vkToken, tokenType: EnumUserAuthType.VK, email: vkUserEmail });
    navigate('/');
  }, [location.hash]);

  const onFinishLogin = async (values: { email: string; password: string }) => {
    const res = await login(values);

    if ('error' in res) {
      setFormValid(false);
      const error = res.error as IErrorResponse;
      message.error(error.data.details[0].errorMessage);
    } else {
      dispatch(setCredentials(res.data));
      navigate('/');
    }
  };

  const onValueChange = () => {
    setFormValid(true);
  };

  const onFinishReset = async (values: { email: string }) => {
    const res = await resetPassword(values);

    if ('error' in res) {
      message.error('Ошибка при восстановлении пароля');
    } else {
      message.success('Пароль отправлен на почту');
      setResetPwd(false);
    }
  };

  return (
    <div className={s.loginFormWrapper}>
      <div className={s.loginFormContainer}>
        <img src={logo} alt="ТЭК лого" className={s.loginIcon} />
        {isResetPwd ? (
          <>
            <RecoveryPwdForm onFinish={onFinishReset} />
            <Button className={s.forgotPwdButton} onClick={() => setResetPwd(false)}>
              Вернуться к авторизации
            </Button>
          </>
        ) : (
          <>
            <LoginForm
              onFinish={onFinishLogin}
              isLoading={isLoading || isLoadingLoginSocials}
              onValuesChange={onValueChange}
              formValid={formValid}
            />
            <div className={s.socialNetworks}>
              <VkLoginButton />
              <div id="google" />
            </div>
            <Button type="link" className={s.forgotPwdButton} onClick={() => setResetPwd(true)}>
              Забыли пароль?
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
