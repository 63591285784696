const accessTokenKey = '#access_token=';
const userEmailKey = 'email=';
export const vkResponseParse = (response: string) => {
  return response.split('&').reduce((acc: { vkToken?: string; vkUserEmail?: string }, item) => {
    if (item.startsWith(accessTokenKey)) {
      acc.vkToken = item.replace(accessTokenKey, '');
    }

    if (item.startsWith(userEmailKey)) {
      acc.vkUserEmail = item.replace(userEmailKey, '');
    }

    return acc;
  }, {});
};
