import { FC } from 'react';
import { Button, Form, FormProps, Input, Upload } from 'antd';
import { TypeCreateDevice, TypeDevice } from 'types/index';
import { CustomForm } from 'components/shared/custom-form';
import { ButtonFormGroup } from 'components/shared/custom-form/button-group-form';
import { beforeUploadImage } from 'components/device/device-info-form/utils';
import { UploadOutlined } from '@ant-design/icons';
import { FormLabel } from 'components/shared/form-label';
import { ImageTypes } from 'variables/image-types';

type Props = FormProps & {
  initialValues: TypeDevice;
  isEditForm: boolean;
  onFinish: (values: TypeCreateDevice) => void;
  rollbackHandler?: () => void;
  loading: boolean;
};

export const DeviceForm: FC<Props> = ({ isEditForm, rollbackHandler, loading, ...props }) => {
  const { initialValues } = props;

  return (
    <CustomForm {...props}>
      <Form.Item name="file" label={<FormLabel label="Фото устройства" />}>
        <Upload.Dragger
          name="file"
          listType="picture"
          multiple={false}
          accept={Object.values(ImageTypes).join(',')}
          beforeUpload={(file) => beforeUploadImage(file, Object.values(ImageTypes))}
          maxCount={1}
          defaultFileList={
            initialValues?.url && initialValues.size
              ? [
                  {
                    uid: initialValues.id.toString(),
                    name: initialValues.name,
                    status: 'done',
                    url: initialValues.url,
                  },
                ]
              : undefined
          }
        >
          <Button icon={<UploadOutlined />}>Кликните на кнопку или перетащите картинку для загрузки изображения</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item name="description" label="Описание устройства устройства">
        <Input.TextArea placeholder="Введите описание устройства" showCount maxLength={250} />
      </Form.Item>

      <Form.Item name="name" label="Название устройства" rules={[{ required: true }]}>
        <Input placeholder="Введите название устройства" />
      </Form.Item>

      <ButtonFormGroup
        btnSubmitText={`${isEditForm ? 'Обновить' : 'Сохранить'} информацию устройства`}
        rollbackHandler={rollbackHandler}
        loading={loading}
      />
    </CustomForm>
  );
};
