import { Outlet } from 'react-router';
import { useSelector } from '../../hooks/use-selector';
import { useMeQuery } from 'store/auth/auth-api';
import { Loader } from 'components/loader';
import { LoginPage } from 'pages/login-page';
import { tokenService } from '../../services/token.service';

export const AuthProvider = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const token = tokenService.getToken();
  const { isLoading, isFetching } = useMeQuery('me', {
    skip: !isAuth && !token,
    refetchOnMountOrArgChange: true,
  });

  if (isFetching || isLoading) {
    return <Loader />;
  }

  return isAuth ? <Outlet /> : <LoginPage />;
};
