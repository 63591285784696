import { EnumUserRole } from 'variables/user';
import { Tag } from 'antd';
import { SettingOutlined, UserOutlined } from '@ant-design/icons';

export const getUserRoleRow = (role: EnumUserRole) => {
  switch (role) {
    case EnumUserRole.USER_WEB:
      return (
        <Tag color="#108ee9" style={{ fontSize: '14px' }} icon={<UserOutlined />}>
          User Web
        </Tag>
      );
    case EnumUserRole.USER_APP:
      return (
        <Tag color="#108ee9" style={{ fontSize: '14px' }} icon={<UserOutlined />}>
          User App
        </Tag>
      );
    case EnumUserRole.ADMIN:
      return (
        <Tag color="#f50" style={{ fontSize: '14px' }} icon={<SettingOutlined />}>
          Admin
        </Tag>
      );
    default:
      return (
        <Tag color="#108ee9" style={{ fontSize: '14px' }} icon={<UserOutlined />}>
          User
        </Tag>
      );
  }
};
