import { message, Modal, Space, Table } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useState } from 'react';
import { TypeDevice } from 'types/index';
import { deviceTableColumns } from './my-device-table-columns';
import { TableSearchInput } from 'components/shared/table-controls/table-search-input';
import { useSelector } from '../../../hooks/use-selector';
import { useDispatch } from '../../../hooks/use-dispatch';
import { removeFavoriteDevice } from 'store/devices/devices.slice';

export const MyDevicesTable = () => {
  const dispatch = useDispatch();
  const favoriteDevices = useSelector((state) => state.devices.favoriteDevicesList);
  const [dataSource, setDataSource] = useState<TypeDevice[]>([]);

  const handleSearchDevice = (searchVal: string) => {
    setDataSource(
      favoriteDevices.filter((device) => {
        return device.name.toLowerCase().includes(searchVal);
      })
    );
  };

  const handleRemoveFavoriteDevice = (id: number) => {
    const device = favoriteDevices.find((device) => device.id === id);

    if (!device) {
      return;
    }

    Modal.confirm({
      title: `Вы действительно хотите удалить устройство из списка "Мои устройства": ${device.name}?`,
      icon: <ExclamationCircleFilled />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Отмена',
      onOk() {
        dispatch(removeFavoriteDevice({ id }));
        message.success('Устройсто успешно удалено из списка "Мои устройства"');
      },
    });
  };

  const columns = deviceTableColumns({ handleRemoveFavoriteDevice });

  return (
    <>
      <Space align="center" style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
        <TableSearchInput onChange={handleSearchDevice} placeholder="Поиск устройств" />
      </Space>
      <Table
        columns={columns}
        dataSource={dataSource.length ? dataSource : favoriteDevices}
        rowKey={(device) => device.id}
        pagination={{ defaultPageSize: 20 }}
        scroll={{ x: true }}
      />
    </>
  );
};
