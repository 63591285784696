import { api } from 'store/api';
import { TypeDevice, TypeUploadDevice } from 'types/index';
import { setDevicesList } from 'store/devices/devices.slice';
import { RcFile } from 'antd/es/upload';
import { BeforeUploadFileType } from 'rc-upload/es/interface';

const devicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDevicesList: builder.query<TypeDevice[], string>({
      query: () => ({
        url: '/web/device-info/all',
      }),
      providesTags: ['Device'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: devicesList } = await queryFulfilled;
          dispatch(setDevicesList({ devicesList }));
        } catch (e) {
          console.log(e);
        }
      },
    }),
    deleteDevice: builder.mutation<string, { id: string }>({
      query: ({ id }) => ({
        url: `/web/device-info/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Device'],
    }),
    createDevice: builder.mutation<TypeDevice, Omit<TypeUploadDevice, 'id'>>({
      query: (device) => {
        const body = new FormData();

        if (device.file) {
          body.append('file', device.file);
        }

        body.append('name', device.name);
        body.append('description', device.description ?? '');

        return {
          url: '/web/device-info/upload',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Device'],
    }),
    updateDevice: builder.mutation<TypeDevice, TypeUploadDevice>({
      query: (device) => {
        const body = new FormData();

        if (device.file) {
          body.append('file', device.file);
        }

        body.append('name', device.name);
        body.append('id', device.id.toString());
        body.append('description', device.description ?? '');

        return {
          url: '/web/device-info/update',
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Device'],
    }),
    uploadDevicePassport: builder.mutation<
      string,
      { deviceId: number; file: Exclude<BeforeUploadFileType, File | boolean> | RcFile }
    >({
      query: ({ deviceId, file }) => {
        const body = new FormData();
        body.append('file', file);

        return {
          url: `/web/passport/upload`,
          method: 'POST',
          params: { deviceId },
          body,
          responseHandler: 'text',
        };
      },
      invalidatesTags: ['Device'],
    }),
    deleteDevicePassport: builder.mutation<string, { id: number }>({
      query: ({ id }) => ({
        url: `/web/passport/delete/${id}`,
        method: 'DELETE',
        responseHandler: 'text',
      }),
      invalidatesTags: ['Device'],
    }),
    uploadDeviceFirmware: builder.mutation<string, { deviceId: number; file: RcFile; version: string }>({
      query: ({ deviceId, file, version }) => {
        const body = new FormData();
        body.append('file', file);

        return {
          url: `/web/firmware/upload`,
          method: 'POST',
          params: {
            deviceId,
            version,
          },
          body,
          responseHandler: 'text',
        };
      },
      invalidatesTags: ['Device'],
    }),
    deleteDeviceFirmware: builder.mutation<string, { id: number }>({
      query: ({ id }) => ({
        url: `/web/firmware/delete/${id}`,
        method: 'DELETE',
        responseHandler: 'text',
      }),
      invalidatesTags: ['Device'],
    }),
  }),
});

export const {
  useGetDevicesListQuery,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDevicePassportMutation,
  useUploadDevicePassportMutation,
  useUploadDeviceFirmwareMutation,
  useDeleteDeviceFirmwareMutation,
} = devicesApi;
