import { Route, Routes } from 'react-router';
import { AuthProvider } from 'providers/auth-provider';
import { MainPage } from 'pages/main-page';
import { NotFoundPage } from 'pages/not-found-page';
import { WelcomePage } from 'pages/welcome-page';
import { UsersPage } from 'pages/users-page';
import { UserCreatePage } from 'pages/user-create-page';
import { UserUpdatePage } from 'pages/user-update-page';
import { DevicesPage } from 'pages/devices-page';
import { DeviceCreatePage } from 'pages/device-create-page';
import { DeviceUpdatePage } from 'pages/device-update-page';
import { MyDevicesPage } from 'pages/my-devices-page';
import { MyDeviceUpdatePage } from 'pages/my-device-update-page';

export const App = () => {
  return (
    <Routes>
      <Route element={<AuthProvider />}>
        <Route path="/" element={<MainPage />}>
          <Route index element={<WelcomePage />} />

          <Route path="users" element={<UsersPage />} />
          <Route path="users/create" element={<UserCreatePage />} />
          <Route path="users/:id" element={<UserUpdatePage />} />

          <Route path="my-devices" element={<MyDevicesPage />} />
          <Route path="my-devices/:id" element={<MyDeviceUpdatePage />} />
          <Route path="my-devices/:id/passport" element={<MyDeviceUpdatePage />} />
          <Route path="my-devices/:id/firmware" element={<MyDeviceUpdatePage />} />

          <Route path="devices" element={<DevicesPage />} />
          <Route path="devices/create" element={<DeviceCreatePage />} />
          <Route path="devices/:id" element={<DeviceUpdatePage />} />
          <Route path="devices/create/passport" element={<DeviceCreatePage />} />
          <Route path="devices/:id/passport" element={<DeviceUpdatePage />} />
          <Route path="devices/create/firmware" element={<DeviceCreatePage />} />
          <Route path="devices/:id/firmware" element={<DeviceUpdatePage />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
