import { TypeCreateDevice, TypeDevice } from 'types/index';
import { FC } from 'react';
import { Tabs } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { DeviceForm } from 'components/device/device-info-form';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { DeviceFirmwares } from 'components/device/device-firmwares';
import { DevicePassports } from 'components/device/device-passports';

type Props = {
  initialValues: TypeDevice;
  onFinishInfo: (values: TypeCreateDevice) => void;
  devicePath: string;
  isLoading: boolean;
  keyPath?: 'devices' | 'my-devices';
};
export const DeviceTabs: FC<Props> = (props) => {
  const { initialValues, onFinishInfo, devicePath, isLoading, keyPath = 'devices' } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const isEditForm = devicePath !== 'create';
  const deviceSettingsPath = `/${keyPath}/${devicePath}`;
  const deviceFirmwaresPath = `/${keyPath}/${devicePath}/firmware`;
  const devicePassportPath = `/${keyPath}/${devicePath}/passport`;

  const rollbackHandler = () => {
    navigate(`/${keyPath}`);
  };

  const tabs = [
    {
      label: (
        <span>
          <SettingOutlined />
          Информация
        </span>
      ),
      key: deviceSettingsPath,
      children: (
        <DeviceForm
          initialValues={initialValues}
          isEditForm={isEditForm}
          onFinish={onFinishInfo}
          rollbackHandler={rollbackHandler}
          loading={isLoading}
        />
      ),
      show: true,
    },
    {
      label: (
        <span>
          <SettingOutlined />
          Паспорт
        </span>
      ),
      key: devicePassportPath,
      children: <DevicePassports initialValues={initialValues.devicePassportResponseList} />,
      show: isEditForm,
    },
    {
      label: (
        <span>
          <SettingOutlined />
          Прошивки
        </span>
      ),
      key: deviceFirmwaresPath,
      children: <DeviceFirmwares firmwares={initialValues.firmwareResponseList} />,
      show: isEditForm,
    },
  ].filter((tab) => tab.show);

  return (
    <Tabs
      activeKey={tabs.find((e) => e.key.includes(location.pathname))?.key}
      onChange={(key) => navigate(`${key}`)}
      size="middle"
      type="card"
      items={tabs}
    />
  );
};
