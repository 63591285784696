import { configureStore } from '@reduxjs/toolkit';
import { api } from './api';
import { authReducer } from 'store/auth/auth.slice';
import { usersReducer } from 'store/users/users.slice';
import { devicesReducer } from 'store/devices/devices.slice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    users: usersReducer,
    devices: devicesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  devTools: true,
});
