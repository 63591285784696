export enum EnumUserRole {
  USER_APP = 'USER_APP',
  USER_WEB = 'USER_WEB',
  ADMIN = 'ADMIN',
}

export enum EnumUserAuthType {
  PASSWORD = 'PASSWORD',
  MOBILE = 'MOBILE',
  VK = 'VK',
  GOOGLE = 'GOOGLE',
}
