import { FC, ReactNode } from 'react';
import { Form, FormProps } from 'antd';

type Props = FormProps & {
  children: ReactNode;
};

const validateMessages = {
  required: 'Это обязательное поле!',
  types: {
    email: '${label} не валидный email!',
  },
};

export const CustomForm: FC<Props> = ({ children, onFinish, ...props }) => {
  const [form] = Form.useForm();

  const handleSubmitForm = (values: typeof props.initialValues) => {
    if (!onFinish) {
      return;
    }

    onFinish(values);
    form.resetFields();
  };

  return (
    <Form layout="vertical" validateMessages={validateMessages} form={form} onFinish={handleSubmitForm} {...props}>
      {children}
    </Form>
  );
};
