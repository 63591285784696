import { Button, message, Modal, Space, Table } from 'antd';
import { userTableColumns } from 'components/user/users-table/user-table-columns';
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TableSearchInput } from 'components/shared/table-controls/table-search-input';
import { useSelector } from '../../../hooks/use-selector';
import { TypeUser } from 'types/index';
import { useDeleteUserMutation } from 'store/users/users-api';

export const UsersTable = () => {
  const users = useSelector((state) => state.users.usersList);
  const [dataSource, setDataSource] = useState<TypeUser[]>([]);
  const [removeUser] = useDeleteUserMutation();

  const handleSearchUser = (searchVal: string) => {
    setDataSource(
      users.filter((user) => {
        return (
          user.email?.toLowerCase().includes(searchVal) ||
          user.firstName?.toLowerCase().includes(searchVal) ||
          user.lastName?.toLowerCase().includes(searchVal)
        );
      })
    );
  };

  const handleDeleteUser = (id: string) => {
    const user = users.find((user) => user.id.toString() === id);
    const firstName = user?.firstName ? ` ${user.firstName}` : '';
    const lastName = user?.lastName ? ` ${user.lastName}` : '';

    Modal.confirm({
      title: `Вы действительно хотите удалить пользователя${firstName}${lastName}?`,
      icon: <ExclamationCircleFilled />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Отмена',
      onOk: async () => {
        const res = await removeUser({ id });
        if ('error' in res) {
          message.error('Ошибка при удалении пользователя');
        } else {
          message.success('Пользователь успешно удален');
        }
      },
    });
  };

  const columns = userTableColumns({ handleDeleteUser });

  return (
    <>
      <Space align="center" style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
        <TableSearchInput onChange={handleSearchUser} placeholder="Поиск пользователей" />
        <Button type="primary" icon={<PlusOutlined />}>
          <Link to="create">Создать пользователя</Link>
        </Button>
      </Space>
      <Table
        columns={columns}
        dataSource={dataSource.length ? dataSource : users}
        rowKey={(user) => user.id}
        pagination={{ defaultPageSize: 20 }}
        scroll={{ x: true }}
      />
    </>
  );
};
