const tokenKey = process.env.REACT_APP_TOKEN_KEY as string;
export const tokenService = {
  saveToken(token: string): void {
    localStorage.setItem(tokenKey, token);
  },
  getToken(): string | null {
    return localStorage.getItem(tokenKey);
  },
  removeToken(): void {
    localStorage.removeItem(tokenKey);
  },
};
