import s from './style.module.scss';
import { Button } from 'antd';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

type Props = {
  btnSubmitText?: string;
  rollbackHandler?: () => void;
  loading?: boolean;
};

export const ButtonFormGroup: FC<Props> = (props) => {
  const { btnSubmitText = 'Сохранить', rollbackHandler, loading } = props;
  const navigate = useNavigate();

  const onRollbackClick = () => {
    if (rollbackHandler) {
      rollbackHandler();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={s.buttonsGroup}>
      <Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={loading}>
        {btnSubmitText}
      </Button>
      <Button icon={<RollbackOutlined />} onClick={onRollbackClick}>
        Назад
      </Button>
    </div>
  );
};
