import React, { FC, ReactNode } from 'react';
import s from './style.module.scss';

type Props = {
  label: ReactNode;
  helper?: ReactNode;
};

export const FormLabel: FC<Props> = (props) => {
  const { label, helper } = props;

  return (
    <div className={s.labelWrapper}>
      <p className={s.label}>{label}</p>
      {helper && <span className={s.helper}>{helper}</span>}
    </div>
  );
};
