import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  useGetDevicesListQuery,
  // useUpdateDeviceMutation
} from 'store/devices/devices-api';
import { useSelector } from '../../hooks/use-selector';
import { Loader } from 'components/loader';
import { ErrorComponent } from 'components/error-component';
import {
  TypeCreateDevice,
  // TypeUpdateDevice
} from 'types/index';
// import {RcFile} from "antd/es/upload";
// import {message} from "antd";
import { DeviceTabs } from 'components/device/device-tabs';

export const MyDeviceUpdatePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, isFetching } = useGetDevicesListQuery('devicesList');
  // const [updateDevice, { isLoading: isLoadingUpdateDevice }] = useUpdateDeviceMutation();
  const favoriteDevices = useSelector((state) => state.devices.favoriteDevicesList);

  if (isLoading || isFetching) {
    return <Loader />;
  }

  const currentDevice = favoriteDevices.find((device) => device.id.toString() === id);

  if (!currentDevice) {
    return (
      <ErrorComponent
        subTitle="Устройство не найдено"
        title="404"
        extraButtonText="Назад"
        handleClickButton={() => navigate(-1)}
      />
    );
  }

  const onFinishInfo = async (device: TypeCreateDevice) => {
    console.log(device);
    // let file: RcFile | undefined;
    //
    // if (device.file?.fileList.length) {
    //     file = device.file.fileList?.[0]?.originFileObj;
    // }
    //
    // const data: TypeUpdateDevice = { id: currentDevice.id, file, name: device.name };
    //
    // const res = await updateDevice(data);
    // if ('error' in res) {
    //     message.error('Ошибка при обновлении устройства');
    // } else {
    //     message.success(`Устройство ${res.data.name} обновлено`);
    //     navigate(-1);
    // }
  };

  return (
    <DeviceTabs
      initialValues={currentDevice}
      onFinishInfo={onFinishInfo}
      devicePath={currentDevice.id.toString()}
      isLoading={false}
      keyPath="my-devices"
    />
  );
};
