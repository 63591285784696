import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenService } from '../../services/token.service';
import { logout } from 'store/auth/auth.slice';

const API_URL = process.env.REACT_APP_API as string;
const uploadEndpoints = ['createDevice', 'updateDevice', 'uploadDevicePassport', 'uploadDeviceFirmware'];

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  headers: {
    Accept: 'application/json',
  },
  prepareHeaders: (headers, { endpoint }) => {
    const token = tokenService.getToken();

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (!uploadEndpoints.includes(endpoint)) {
      headers.set('Content-Type', 'application/json');
    }

    return headers;
  },
});

const baseQueryWithAuth = async (args: FetchArgs, api: BaseQueryApi, extra: NonNullable<unknown>) => {
  const res = await baseQuery(args, api, extra);

  if (res.error?.status === 401 || res.error?.status === 403) {
    api.dispatch(logout());
  }

  return res;
};
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({}),
  tagTypes: ['User', 'Device', 'Me'],
});
