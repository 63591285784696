import { FC } from 'react';
import s from './style.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

type Props = {
  placeholder?: string;
  onChange: (value: string) => void;
};

export const TableSearchInput: FC<Props> = (props) => {
  const { placeholder = 'Поиск', onChange } = props;

  return (
    <Input
      className={s.search}
      addonBefore={<SearchOutlined />}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
