import { DevicesTable } from 'components/device/devices-table';
import { Loader } from 'components/loader';
import { useGetDevicesListQuery } from 'store/devices/devices-api';

export const DevicesPage = () => {
  const { isLoading, isFetching } = useGetDevicesListQuery('devicesList');

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return <DevicesTable />;
};
