import { ErrorComponent } from 'components/error-component';
import { useNavigate } from 'react-router';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const handleClickBackHome = () => {
    navigate('/');
  };
  return <ErrorComponent handleClickButton={handleClickBackHome} />;
};
