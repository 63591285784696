import s from './style.module.scss';
import { ColumnsType } from 'antd/es/table';
import { TypeDevice } from 'types/index';
import { ActionRow } from 'components/shared/table-controls/action-row';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';

type Params = {
  handleRemoveFavoriteDevice: (id: number) => void;
};

export const deviceTableColumns = (params: Params): ColumnsType<TypeDevice> => {
  const { handleRemoveFavoriteDevice } = params;

  return [
    {
      title: 'Фото',
      dataIndex: 'url',
      render: (photo: string | null) =>
        photo ? <img className={s.deviceImg} src={photo} alt="Фото девайса" /> : <span>Фото не загружено</span>,
    },
    {
      title: 'Имя',
      dataIndex: 'name',
    },
    {
      title: 'Действие',
      key: 'action',
      fixed: 'right',
      width: 360,
      render: (_, device) => (
        <ActionRow disableDelete={false} entityId={device.id.toString()}>
          <Button danger onClick={() => handleRemoveFavoriteDevice(device.id)} icon={<DeleteOutlined />}>
            Удалить из моих устройств
          </Button>
        </ActionRow>
      ),
    },
  ];
};
