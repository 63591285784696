import { FC, useState } from 'react';
import s from './style.module.scss';
import { Avatar, Button, Card, message, Modal } from 'antd';
import { TypeFirmware, TypeUploadFile } from 'types/index';
import { DeleteOutlined, FileOutlined, PlusOutlined } from '@ant-design/icons';
import { FirmwareForm } from 'components/device/device-firmwares/firmware-form';
import { useParams } from 'react-router-dom';
import { useDeleteDeviceFirmwareMutation, useUploadDeviceFirmwareMutation } from 'store/devices/devices-api';
import { FirmwareDescription } from 'components/shared/firmware-description';

type Props = {
  firmwares: TypeFirmware[];
};

const defaultFirmware: TypeFirmware = {
  id: 0,
  name: '',
  type: '',
  createdAt: '',
  url: '',
  version: '',
};

export const DeviceFirmwares: FC<Props> = (props) => {
  const { firmwares } = props;

  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadFirmware, { isLoading }] = useUploadDeviceFirmwareMutation();
  const [deleteFirmware] = useDeleteDeviceFirmwareMutation();

  const handleClickDelete = async (firmwareId: number) => {
    const res = await deleteFirmware({ id: firmwareId });

    if ('error' in res) {
      message.error('Ошибка при удалении прошивки');
      return false;
    } else {
      message.success(res.data);
      return true;
    }
  };

  const handleUploadFirmware = async (values: { version: string; file: TypeUploadFile }) => {
    const { version } = values;

    const file = values.file?.fileList?.[0]?.originFileObj;

    if (!file || !id) {
      return;
    }

    const res = await uploadFirmware({ deviceId: +id, file: file, version });

    if ('error' in res) {
      message.error('Ошибка при загрузке прошивки');
    } else {
      message.success(res.data);
      setIsModalOpen(false);
    }
  };

  return (
    <div className={s.firmwaresContainer}>
      <Modal
        title="Загрузить прошивку устройства"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        destroyOnClose
      >
        <FirmwareForm initialValues={defaultFirmware} onFinish={handleUploadFirmware} isLoading={isLoading} />
      </Modal>
      <div className={s.firmwaresControls}>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>
          Загрузить прошивку
        </Button>
      </div>
      <div className={s.firmwaresWrapper}>
        {firmwares.map((firmware) => {
          return (
            <Card
              className={s.firmwareCard}
              key={firmware.id}
              actions={[<DeleteOutlined key="delete" onClick={() => handleClickDelete(firmware.id)} />]}
            >
              <Card.Meta
                avatar={<Avatar icon={<FileOutlined />} />}
                title={firmware.name}
                description={<FirmwareDescription version={firmware.version} createdAt={firmware.createdAt} />}
              />
            </Card>
          );
        })}
      </div>
    </div>
  );
};
