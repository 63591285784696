import s from './style.module.scss';
import vkLogo from 'assets/images/vk.png';

const vkId = process.env.REACT_APP_VK_ID as string;
const host =
  process.env.NODE_ENV === 'production'
    ? (process.env.REACT_APP_HOST_PROD as string)
    : (process.env.REACT_APP_HOST_LOCAL as string);
export const VkLoginButton = () => {
  const onVkLoginHandler = () => {
    window.location.href = `https://oauth.vk.com/authorize?client_id=${vkId}&redirect_uri=${host}&response_type=token&v=5.131&scope=email,offline`;
  };

  return (
    <button className={s.vkLoginButton} onClick={onVkLoginHandler}>
      <img src={vkLogo} alt="VK" />
    </button>
  );
};
