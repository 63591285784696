import { Button, message, Modal, Space, Table } from 'antd';
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TypeDevice } from 'types/index';
import { deviceTableColumns } from './device-table-columns';
import { TableSearchInput } from 'components/shared/table-controls/table-search-input';
import { useSelector } from '../../../hooks/use-selector';
import { useDeleteDeviceMutation } from 'store/devices/devices-api';

export const DevicesTable = () => {
  const devices = useSelector((state) => state.devices.devicesList);
  const [dataSource, setDataSource] = useState<TypeDevice[]>([]);
  const [deleteDevice] = useDeleteDeviceMutation();

  const handleSearchDevice = (searchVal: string) => {
    setDataSource(
      devices.filter((device) => {
        return device.name.toLowerCase().includes(searchVal);
      })
    );
  };

  const handleDeleteDevice = (id: string) => {
    const device = devices.find((device) => device.id.toString() === id);

    if (!device) {
      return;
    }

    Modal.confirm({
      title: `Вы действительно хотите удалить устройство: ${device.name}?`,
      icon: <ExclamationCircleFilled />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Отмена',
      onOk: async () => {
        const res = await deleteDevice({ id });
        if ('error' in res) {
          message.error('Ошибка при удалении устройства');
        } else {
          message.success('Устройство успешно удалено');
        }
      },
    });
  };

  const columns = deviceTableColumns({ handleDeleteDevice });

  return (
    <>
      <Space align="center" style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
        <TableSearchInput onChange={handleSearchDevice} placeholder="Поиск устройств" />
        <Button type="primary" icon={<PlusOutlined />}>
          <Link to="create">Создать устройство</Link>
        </Button>
      </Space>
      <Table
        columns={columns}
        dataSource={dataSource.length ? dataSource : devices}
        rowKey={(device) => device.id}
        pagination={{ defaultPageSize: 20 }}
        scroll={{ x: true }}
      />
    </>
  );
};
