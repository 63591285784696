import s from './style.module.scss';
import { ColumnsType } from 'antd/es/table';
import { TypeDevice } from 'types/index';
import { ActionRow } from 'components/shared/table-controls/action-row';
import { Divider } from 'antd';
import { AddFavoriteButton } from 'components/device/devices-table/device-table-columns/add-favorite-button';
import { NoDataRow } from 'components/shared/table-controls/no-data-row';

type Params = {
  handleDeleteDevice: (id: string) => void;
};

export const deviceTableColumns = (params: Params): ColumnsType<TypeDevice> => {
  const { handleDeleteDevice } = params;

  return [
    {
      title: 'Фото',
      dataIndex: 'url',
      render: (photo: string, device) =>
        device.size ? <img className={s.deviceImg} src={photo} alt="Фото девайса" /> : <span>Фото не загружено</span>,
    },
    {
      title: 'Имя',
      dataIndex: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      render: (description: string | null) =>
        description ? <p className={s.deviceDescription}>{description}</p> : <NoDataRow />,
    },
    {
      title: 'Действие',
      key: 'action',
      fixed: 'right',
      width: 360,
      render: (_, device) => (
        <ActionRow
          disableDelete={false}
          onDelete={() => handleDeleteDevice(device.id.toString())}
          entityId={device.id.toString()}
        >
          <Divider type="vertical" />
          <AddFavoriteButton device={device} />
        </ActionRow>
      ),
    },
  ];
};
