import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeDevice } from 'types/index';

type initialState = {
  devicesList: TypeDevice[];
  favoriteDevicesList: TypeDevice[];
};

const initialState: initialState = {
  devicesList: [],
  favoriteDevicesList: [],
};

const devicesSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setDevicesList: (state, action: PayloadAction<{ devicesList: TypeDevice[] }>) => {
      state.devicesList = action.payload.devicesList;
    },
    addFavoriteDevice: (state, action: PayloadAction<TypeDevice>) => {
      state.favoriteDevicesList.push(action.payload);
    },
    removeFavoriteDevice: (state, action: PayloadAction<{ id: number }>) => {
      const indexDevice = state.favoriteDevicesList.findIndex((d) => d.id === action.payload.id);
      state.favoriteDevicesList.splice(indexDevice, 1);
    },
  },
});

export const { setDevicesList, addFavoriteDevice, removeFavoriteDevice } = devicesSlice.actions;
export const { reducer: devicesReducer } = devicesSlice;
