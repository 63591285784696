import { RcFile } from 'antd/es/upload';
import { message, Upload } from 'antd';

const checkFileNameType = (name: string, types?: string[]) => {
  if (!types) {
    return false;
  }

  let res = false;

  for (let i = 0; i < types.length; i++) {
    res = !!name.endsWith(types[i]);
  }

  return res;
};
export const beforeUploadImage = (file: RcFile, types?: string[], maxSizeByte?: number) => {
  if (maxSizeByte && file.size > maxSizeByte) {
    message.error(`File ${file.name} size is too big`);
    return Upload.LIST_IGNORE;
  }

  const isCurrentType = file.type ? types?.includes(file.type) : checkFileNameType(file.name, types);

  if (types && !isCurrentType) {
    const cutTypes = types.join('/');

    message.error(`${file.name} is not a ${cutTypes} file`);
    return isCurrentType || Upload.LIST_IGNORE;
  }

  return false;
};
