import { UsersTable } from 'components/user/users-table';
import { Loader } from 'components/loader';
import { useGetUsersListQuery } from 'store/users/users-api';

export const UsersPage = () => {
  const { isLoading, isFetching } = useGetUsersListQuery('usersList');

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return <UsersTable />;
};
