import { FC } from 'react';
import { Form, Input, FormProps, Select } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { TypeUser } from 'types/index';
import { CustomForm } from 'components/shared/custom-form';
import { EnumUserAuthType, EnumUserRole } from 'variables/user';
import { ButtonFormGroup } from 'components/shared/custom-form/button-group-form';

type Props = FormProps & {
  initialValues: TypeUser;
  isEditForm: boolean;
  onFinish: (values: TypeUser) => void;
  isLoading: boolean;
};

export const UserForm: FC<Props> = ({ isEditForm, isLoading, ...props }) => {
  const { initialValues } = props;

  return (
    <CustomForm {...props}>
      <Form.Item name="role" valuePropName="checked" label="Роль пользователя" rules={[{ required: true }]}>
        <Select defaultValue={initialValues.role}>
          {Object.values(EnumUserRole).map((role) => (
            <Select.Option key={role} value={role}>
              {role}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="email"
        label="Email пользователя"
        rules={[{ required: initialValues.authType !== EnumUserAuthType.VK, type: 'email' }]}
      >
        <Input
          prefix={<MailOutlined />}
          placeholder="Введите email пользователя"
          autoComplete="off"
          disabled={isEditForm}
        />
      </Form.Item>

      <Form.Item name="firstName" label="Имя пользователя" rules={[{ required: true }]}>
        <Input placeholder="Введите имя пользователя" />
      </Form.Item>

      <Form.Item name="lastName" label="Фамилия пользователя" rules={[{ required: true }]}>
        <Input placeholder="Введите фамилию пользователя" />
      </Form.Item>

      <Form.Item name="organization" label="Организация пользователя" rules={[{ required: true }]}>
        <Input placeholder="Введите организацию пользователя" />
      </Form.Item>

      <Form.Item
        name="authType"
        valuePropName="checked"
        label="Тип авторизации пользователя"
        rules={[{ required: true }]}
      >
        <Select defaultValue={initialValues.authType} disabled>
          {Object.values(EnumUserAuthType).map((role) => (
            <Select.Option key={role} value={role}>
              {role}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <ButtonFormGroup
        btnSubmitText={isEditForm ? 'Cохранить пользователя' : 'Создать пользователя'}
        loading={isLoading}
      />
    </CustomForm>
  );
};
