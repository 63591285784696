import s from './style.module.scss';
import { FC } from 'react';
import { Button, Result } from 'antd';
import { ResultStatusType } from 'antd/es/result';

type Props = {
  handleClickButton?: () => void;
  status?: ResultStatusType;
  title?: string;
  subTitle?: string;
  extraButtonText?: string;
};

export const ErrorComponent: FC<Props> = (props) => {
  const {
    handleClickButton,
    subTitle = 'Извините, страница не существует',
    status = '404',
    title = '404',
    extraButtonText,
  } = props;

  return (
    <Result
      className={s.notFoundContainer}
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        handleClickButton ? (
          <Button type="primary" onClick={handleClickButton}>
            {extraButtonText ? extraButtonText : 'Вернуться на главную страницу'}
          </Button>
        ) : undefined
      }
    />
  );
};
