import { FC } from 'react';
import { CustomForm } from 'components/shared/custom-form';
import { Button, Form, FormProps, Input, Upload } from 'antd';
import { FormLabel } from 'components/shared/form-label';
import { beforeUploadImage } from 'components/device/device-info-form/utils';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { TypeFirmware } from 'types';

type Props = FormProps & {
  initialValues: TypeFirmware;
  isLoading: boolean;
};

export const FirmwareForm: FC<Props> = ({ isLoading, ...props }) => {
  const { initialValues } = props;

  return (
    <CustomForm {...props}>
      <Form.Item name="version" label="Версия прошивки" rules={[{ required: true }]}>
        <Input placeholder="Введите версию прошивки" />
      </Form.Item>

      <Form.Item name="file" label={<FormLabel label="Фото устройства" />}>
        <Upload.Dragger
          listType="picture"
          multiple={false}
          accept=".tbf"
          beforeUpload={(file) => beforeUploadImage(file, ['.tbf'])}
          maxCount={1}
          defaultFileList={
            initialValues.url
              ? [
                  {
                    uid: initialValues.id.toString(),
                    name: initialValues.name,
                    status: 'done',
                  },
                ]
              : undefined
          }
        >
          <Button icon={<UploadOutlined />}>Кликните на кнопку для загрузки прошивки</Button>
        </Upload.Dragger>
      </Form.Item>

      <Button style={{ width: '100%' }} type="primary" icon={<SaveOutlined />} htmlType="submit" loading={isLoading}>
        Загрузить прошивку
      </Button>
    </CustomForm>
  );
};
