import s from './style.module.scss';
import { Spin } from 'antd';

export const Loader = () => {
  return (
    <div className={s.loaderWrapper}>
      <Spin size="large" className={s.loader} />
    </div>
  );
};
