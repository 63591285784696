import { FC } from 'react';
import s from '../style.module.scss';
import { Button, Form, FormProps, Input, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';

export const RecoveryPwdForm: FC<FormProps> = ({ ...props }) => {
  return (
    <Form {...props}>
      <div className={s.headerResetForm}>
        <Typography.Title level={3}>Забыли пароль?</Typography.Title>
        <Typography.Text>Пожалуйста введите ваш email, и мы вышлем вам новый пароль</Typography.Text>
      </div>
      <Form.Item
        name="email"
        rules={[
          { type: 'email', message: 'Некорректный E-mail!' },
          { required: true, message: 'Пожалуйста введите своей Email!' },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Введите ваш email" />
      </Form.Item>

      <Button type="primary" htmlType="submit" className={s.submitButton}>
        Отправить
      </Button>
    </Form>
  );
};
