import { FC } from 'react';
import s from './style.module.scss';
import { formatDate } from '../../../lib/format-date';

type Props = {
  createdAt: string;
  version: string;
};
export const FirmwareDescription: FC<Props> = (props) => {
  const { createdAt, version } = props;

  return (
    <div>
      <p className={s.descriptionItem}>Версия: {version}</p>
      <p className={s.descriptionItem}>Дата создания {formatDate(createdAt)}</p>
    </div>
  );
};
