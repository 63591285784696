import { TypeCreateDevice, TypeDevice } from 'types/index';
import { DeviceTabs } from 'components/device/device-tabs';
import { useCreateDeviceMutation } from 'store/devices/devices-api';
import { message } from 'antd';
import { useNavigate } from 'react-router';

const defaultDeviceData: TypeDevice = {
  id: 0,
  name: '',
  url: '',
  photoName: '',
  size: 0,
  type: '',
  description: null,
  firmwareResponseList: [],
  devicePassportResponseList: [],
};

export const DeviceCreatePage = () => {
  const navigate = useNavigate();
  const [createDevice, { isLoading }] = useCreateDeviceMutation();

  const onFinishDeviceInfo = async (device: TypeCreateDevice) => {
    const res = await createDevice({
      name: device.name,
      file: device.file?.fileList?.[0]?.originFileObj,
      description: device.description,
    });

    if ('error' in res) {
      message.error('Ошибка при создании устройства');
    } else {
      message.success(`Устройство ${res.data.name} создано`);
      navigate(-1);
    }
  };

  return (
    <DeviceTabs
      initialValues={defaultDeviceData}
      onFinishInfo={onFinishDeviceInfo}
      devicePath="create"
      isLoading={isLoading}
    />
  );
};
